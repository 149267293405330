.header{
    display: flex;
    justify-content: space-between;
    font-family: helvetica;
    background-color: rgba(225,225,225,0.2);
}
.nav_top{
    display: flex;
}
.nav_bottom{
    display: flex;
    align-self: flex-end;
    padding: 1em;
}
.nav_bottom img{
    width: 1.25em;
    margin: .5em;
}
.nav_items{
    margin: 1em;
}
@media(max-width: 420px){
    .header{
        flex-direction: column;
    }
    .nav_bottom{
        order: -1;
    }
    .nav_top{
        flex-direction: column;
        text-align: center;
    }
    .nav_items{
        box-shadow: 5px 5px 5px;
        margin: 0;
        padding: 1em;
    }
}
