.box{
  border: solid #006D77 4px ;
  border-left: hidden;
  border-bottom: hidden;
  width: 95vw;
  height: 100%;
}
.App{
  font-family: kefa;
}
h1{
  text-align: center;
  margin-top: 0;
}
.white_title{
  color: white;
  margin-top: 1em;
}
a{
  text-decoration: none;
  color: white;
}