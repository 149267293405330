
.home{
    color: #006D77;
    background-color: rgba(225,225,225,0.5);
}
.home_title{
    padding-top: 1em;
}
h2{
    text-align: center;
    font-weight: 100;
}
.logo{
    display: flex;
    width: 401px;
    height: 542px;
    border: solid #006D77 3px;
    margin: auto;
    align-self: center;
}
.awards{
    display: flex;
    flex-wrap: wrap;
}
.award{
    background-color: #83C5BE;
    margin: 1em;
    padding: 1em;
}
@media(max-width:420px){
    .logo{
        width: 75%;
        height: 75%;
    }
}