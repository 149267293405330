.box_contact{
    border: solid #006D77 4px ;
    border-left: hidden;
    border-bottom: hidden;
    width: 95vw;
    height: 100%;
}
.contact_title{
    color: #ffffff;
}
.contact_form_box{
    display: flex;
    padding: 1em;
    background-image: linear-gradient(to right, #83C5BE, #ffffff)  
}
.contact_left{
    width: 50vw;
    height: 100vh;
    padding: 5em;
}
.contact_right{
    width: 50vw;
    height: 30em;
    border: solid black 2px;
    box-shadow: 5px 5px 15px;
    margin-top: 10em;
    background-color: #ffffff;
}
.contact_right form{
    display: flex;
    flex-direction: column;
    padding: 1em;
}
.contact_right input {
    margin: 1em;
}
.submit{
    width: 6em;
    align-self: flex-end;
    margin: 1em;
}
@media(max-width:420px){
    .contact_form_box{
        flex-direction: column;
        width: 100vw;
        padding: 0;
    }
    .contact_left{
        margin: 0;
        height: 10vh;
    }
    .contact_right{
        margin: 0;
        width: 80vw;
        margin: 0;
        margin-top: 0;
        height: 20%;
        align-self: center;
    }
    .contact_title{
        margin-top: 0;
    }
    .box_contact{
        border-top: hidden;
        border-left: hidden;
        border-right: hidden;
        border-bottom: hidden;
    }
}