body{
    background-color: #83C5BE; 
}
.work_container{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding-left: 5em;
}
.work_box{
    background-color: rgba(225,225,225,0.5);
    margin: 1em;
    height: 100%;
    padding: .1em;
}
.work_vids{
   width: 20em;
   height: 10em;
   margin: .5em;
}
.work_photos{
    width: 10em;
    height: 80%;
    margin: .5em;
}
.work_caption{
    width: 15em;
    font-size: .75rem;
    margin: auto;
    text-align: center;
}
@media(max-width:420px){
    .work_vids{
        display: flex;
        width: 92%;
        height: 92%;
        align-self: center;
    }
    .work_photos{
        display: flex;
        width: 92%;
        height: 92%;
        align-self: center;
    }
    .work_box{
        width: 75%;
        height: 75%;
    }
}